import {
	Modal,
	Button,
	Form,
	FormLayout,
	TextField,
	Select,
	Icon,
	Thumbnail
} from "@shopify/polaris";
import React, { useEffect, useState, useCallback } from "react";
import { DeleteMajorMonotone, CircleTickMajorTwotone, EditMajorMonotone } from "@shopify/polaris-icons";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { CollectionSelectPolaris } from "../../../product/collections/CollectionSelectPolaris";
import { TagsSelectPolaris } from "../../../product/tags/TagsSelectPolaris";
import CrawlItemImage from "../CrawlItemImage";
import styled from "styled-components";

const EDIT_CRAWL_ITEM = gql`
	mutation updateTiktokCrawlProduct($input: TiktokCrawlProductInput!) {
		updateTiktokCrawlProduct(input: $input) {
			id
		}
	}
`;

const LIST_TEMPLATES = gql`
	query getTiktokProductTemplates($filter: GetTiktokProductTemplateFilter!) {
		getTiktokProductTemplates(filter: $filter) {
			total
			nodes {
				value: id
				label: title
			}
		}
	}
`;

export default function EditCrawlItem({ item, onEdited }) {
	let {
		mainImage,
		id,
		title,
		createdAt,
		originUrl,
		collections,
		tags,
		templateID,
		image2,
		image3,
		image4,
		image5,
		image6,
		image7,
		image8,
		image9,
		collectionIDs,
		tagIDs,
	} = item;

	const [open, setOpen] = useState(false);
	const [openEditUrlModal, setOpenEditUrlModal] = useState(false);
	const toggleShowModal = useCallback(() => setOpen((prev) => !prev), []);
	const toggleShowEditUrlModal = useCallback(() => setOpenEditUrlModal((prev) => !prev), []);

	const [listTemplates, setListTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [itemTitle, setItemTitle] = useState('');
	const [itemOriginUrl, setItemOriginUrl] = useState('');
	const [itemCollections, setItemCollections] = useState([]);
	const [itemTags, setItemTags] = useState();
	const [disabledImageUrls, setDisabledImageUrls] = useState([]);
	const [galleryImageUrls, setGalleryImageUrls] = useState([]);
	const [mainImageUrl, setMainImageUrl] = useState('');
	const [editImageUrl, setEditImageUrl] = useState({oldUrl: null, newUrl: null});

	const [errors, setErrors] = useState({
		title: null,
		originUrl: null,
		template: null,
		collections: null,
		tags: null,
		mainImage: null,
	});

	const [updateTiktokCrawlProduct, { loading: mutationLoading }] = useMutation(EDIT_CRAWL_ITEM);

	const handleActionEdit = async (item) => {
		try {
			let input = {
				ID: item.id,
				title: itemTitle,
				originUrl: itemOriginUrl,
				mainImage: mainImageUrl,
				templateID: selectedTemplate,
				mainImage: mainImageUrl,
			};

			let collectionIDs = [];
			let tagIDs = [];
			if (itemCollections.length > 0) {
				itemCollections.map((item) => {
					if ( item.id ) {
						collectionIDs.push(item.id);
					}
				});
			}
			if (itemTags.length > 0) {
				itemTags.map((item) => {
					if ( item.id ) {
						tagIDs.push(item.id);
					}
				});
			}
			input.collectionIDs = collectionIDs;
			input.tagIDs = tagIDs;
			

			let newGalleryUrls = galleryImageUrls.filter(item => !disabledImageUrls.includes(item)).reduce((acc, value, index) => {
				acc[`image${index + 2}`] = value;
				return acc;
			}, {});

			if (Object.keys(newGalleryUrls).length > 0) {
				input = {...input, ...newGalleryUrls};
			}
			const response = await updateTiktokCrawlProduct({
				variables: {
					input
				},
			});
			if (
				response &&
				response?.data &&
				response?.data?.updateTiktokCrawlProduct
			) {
				let createRes = response?.data?.updateTiktokCrawlProduct;

				if (onEdited) {
					if (createRes) {
						onEdited({
							type: "success",
							message: "The selected item is updated",
						});
					} else {
						onEdited({
							type: "fail",
							message: "Could not update the selected item",
						});
					}
				}
			}
		} catch (ex) {
			if (onEdited) {
				onEdited({ type: "fail", message: ex.toString() });
			}
		}
		setOpen(false);
	};

	useEffect(() => {
		let urls = [
			image2 || null,
			image3 || null,
			image4 || null,
			image5 || null,
			image6 || null,
			image7 || null,
			image8 || null,
			image9 || null,
		];
		setGalleryImageUrls(urls);

		setSelectedTemplate(templateID);
		setItemTitle(title);
		setItemOriginUrl(originUrl);
		setItemCollections(collections);
		setItemTags(tags);
		setDisabledImageUrls([]);
		setMainImageUrl(mainImage);
		setEditImageUrl({oldUrl: null, newUrl: null});
	}, [item]);

	const {
		loading: queryLoading,
		error: queryError,
		data: queryData,
	} = useQuery(LIST_TEMPLATES, {
		variables: {
			filter: {
				limit: 500,
				offset: 0,
			},
		},
	});
	useEffect(() => {
		let nodes = queryData?.getTiktokProductTemplates?.nodes;
		if (nodes?.length > 0) {
			setListTemplates(nodes);
		} else {
			setListTemplates([]);
		}
	}, [queryData]);

	const handleDisableImage = (imageUrl, reEnable=false) => {
		setDisabledImageUrls((prevUrls) => {
			let updatedUrls;
			if (reEnable) {
				updatedUrls = prevUrls.filter((url) => url !== imageUrl);
			} else {
				updatedUrls = [...prevUrls, imageUrl];
			}
			return updatedUrls;
		});
	}

	const handleEditImage = (imageUrl) => {
		setOpenEditUrlModal(true);
		setEditImageUrl({
			oldUrl: imageUrl,
			newUrl: imageUrl,
		});
	}

	const handleEditImageUrl = () => {
		setGalleryImageUrls((prev) => prev.map(item => item === editImageUrl.oldUrl ? editImageUrl.newUrl : item));
		setEditImageUrl({oldUrl: null, newUrl: null});
		setOpenEditUrlModal(false);
	}

	return (
		<>
			<Button size="small" type={"primary"} onClick={toggleShowModal}>
				Edit
			</Button>

			<Modal
				open={open}
				title={`Edit crawl item`}
				onClose={toggleShowModal}
				sectioned
				primaryAction={{
					content: "Submit",
					onAction: () => handleActionEdit(item),
					loading: mutationLoading,
				}}
				secondaryActions={[
					{ content: "Cancel", onAction: toggleShowModal },
				]}
				fluidContent
				large
			>
				<Wrapper>
					<Form
						onSubmit={() =>
							console.log("EditCrawlItem form submited")
						}
					>
						<FormLayout>
							<div>
								<TextField
									label="Title"
									value={itemTitle}
									id="title"
									type="text"
									error={errors.title}
									onChange={(val) => setItemTitle(val)}
								/>
							</div>
							<div>
								<TextField
									label="Origin URL"
									value={itemOriginUrl}
									id="originUrl"
									type="text"
									error={errors.originUrl}
									onChange={(val) => setItemOriginUrl(val)}
								/>
							</div>
							<div>
								<CollectionSelectPolaris
									label="Collections"
									required
									allowMultiple
									getCoupleValue
									value={itemCollections}
									errorMessage={errors.collections}
									hasPopular
									haveQuickAdd
									limit={200}
									isAssortment
									onChange={(values) =>
										setItemCollections(values)
									}
								/>
							</div>
							<div>
								<TagsSelectPolaris
									label="Tags"
									required
									allowMultiple
									value={itemTags}
									errorMessage={errors.tags}
									haveQuickAdd
									limit={200}
									onChange={(values) => setItemTags(values)}
								/>
							</div>
							<div>
								<Select
									label="Tiktok Product Template"
									options={listTemplates}
									onChange={(val) => setSelectedTemplate(val)}
									value={selectedTemplate}
									errorMessage={errors.template}
								/>
							</div>

							<div>
								<label>Main Image</label>
								<div>
									<CrawlItemImage
										imageUrl={mainImageUrl}
										galleryImageUrls={galleryImageUrls}
									/>
								</div>
							</div>



							{galleryImageUrls && galleryImageUrls.filter((imageUrl) => imageUrl).length > 0 ? (
								<div className="crawl-item-images">
									<label>Other Images</label>
									<div className="item-images-wrap">
										{galleryImageUrls
											.filter((imageUrl) => imageUrl)
											.map((imageUrl, index) => (
												<div
													key={imageUrl || index}
													className="item-image"
												>
													{ disabledImageUrls.includes(imageUrl) ? (
														<div className="action-re-enable-image">
															<Button
																plain
																icon={
																	CircleTickMajorTwotone
																}
																onClick={() => handleDisableImage(imageUrl, true)}
															/>
														</div>
													) : (
														<div className="action-delete-image">
															<Button
																plain
																icon={
																	EditMajorMonotone
																}
																onClick={() => handleEditImage(imageUrl)}
															/>

															<Button
																plain
																icon={
																	DeleteMajorMonotone
																}
																onClick={() => handleDisableImage(imageUrl, false)}
															/>
														</div>
													)}
													
													<div
														style={{
															display: "flex",
															justifyContent:
																"center",
														}}
													>
														<CrawlItemImage
															imageUrl={imageUrl}
															galleryImageUrls={
																galleryImageUrls
															}
														/>
													</div>
												</div>
											))}
									</div>
								</div>
							) : null}
						</FormLayout>
					</Form>
				</Wrapper>
			</Modal>

			<Modal
				open={openEditUrlModal}
				title={`Edit image url`}
				onClose={toggleShowEditUrlModal}
				sectioned
				primaryAction={{
					content: "Save",
					onAction: handleEditImageUrl,
					disabled: (!editImageUrl || (editImageUrl && editImageUrl.length == 0))
				}}
				secondaryActions={[
					{ content: "Cancel", onAction: toggleShowEditUrlModal },
				]}
			>
				<div>
					<TextField
						label="Image URL"
						value={editImageUrl?.newUrl || null} 
						id="title"
						type="text"
						// error={errors.title}
						onChange={(val) => setEditImageUrl((prev) => ({...prev, newUrl: val}))}
					/>
				</div>
				{ editImageUrl && editImageUrl.newUrl && editImageUrl.newUrl.length > 0 ? (
					<div className="edit-url-preview" style={{marginTop: '15px'}}>
						<div>
							<label>Preview</label>
						</div>
						<Thumbnail size="large" alt="" source={editImageUrl?.newUrl || null} />
					</div>
				) : null}
			</Modal>
		</>
	);
}

const Wrapper = styled.div`
	.item-images-wrap {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	.item-image {
		position: relative;
	}

	.action-delete-image {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 100;
		display: none;

		.Polaris-Button {
			margin-right: 5px;
		}
		.Polaris-Button:last-child {
			margin-right: 0;
		}
	}

	.item-image:hover .action-delete-image {
		display: block;
	}

	.action-re-enable-image {
		display: flex;
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		width: 100%;
		height: 100%;
		justify-content: center;
		background: #ff000047;

		.Polaris-Icon {
			display: none;
			color: green;
			width: 32px;
			height: 32px;

			svg {
				fill: white;
			}
		}
		&:hover .Polaris-Icon {
			display: block;
		}
	}
`;
