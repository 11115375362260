import { useQuery } from "@apollo/react-hooks";
import { Modal } from "@shopify/polaris";
import React, { useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import { elementContains } from "../../../helper";
import { GET_CONNECTED_APP } from "../../app/apps";
import { SkeletonPagePolaris } from "../../shared/SkeletonPagePolaris";
import { PODApp } from "../../../config";
import { useAppContext } from "../../../context";
import { GET_TASK_BY_ID } from "../../../graphql/queries";
import { get } from "lodash";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
`;

export const ConfigDesign = (props) => {
  // Props
  const { open, toggleShowModal, task } = props;
  const { currentUser } = useAppContext();
  const userId = currentUser?.id;

  const { id } = task || {};
  const { data: dataTask } = useQuery(GET_TASK_BY_ID, {
    variables: {
      id: id,
    },
    skip: !id,
  });

  // Query
  const { data, loading } = useQuery(GET_CONNECTED_APP, {
    fetchPolicy: "network-only",
  });
  const getPODApp = data?.getConnectedApp.find(
    (appItem) => appItem.app === "POD",
  );

  // Markup
  useEffect(() => {
    let domCD = document.querySelector("#config-design_wrap");
    let domModal = document.querySelectorAll(".Polaris-Modal-Section");
    let domParents = document.querySelectorAll(".Polaris-Modal-Dialog__Modal");
    for (let domChild of domModal) {
      if (elementContains(domChild, domCD)) {
        domChild.setAttribute("style", "padding: 0");
      }
    }
    for (let domParent of domParents) {
      if (elementContains(domParent, domCD)) {
        domParent.setAttribute(
          "style",
          "max-width: calc(100% - 10rem); height: calc(100vh)",
        );
      }
    }
  });

  // var eventMethod = window.addEventListener
  //   ? "addEventListener"
  //   : "attachEvent";
  // var eventer = window[eventMethod];
  // var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
  // // Listen to message from child window
  // console.log(messageEvent, eventMethod, eventer, "messageEvent");
  // eventer(messageEvent, toggleShowModal, false);

  // window.closeModal = function hanldeCloseModal(status) {
  //   console.log("hanldeCloseModal", status);
  //   toggleShowModal(!open);
  //   toggleActive();
  //   onNotificationChange({
  //     message: "Your request is processing, please check later.",
  //     isError: false,
  //   });
  // };

  useLayoutEffect(() => {
    const handleMessage = (event) => {
      if (open && event.data?.taskId === task.id) {
        toggleShowModal(false);
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pKey = userId ? `mb_user_id=${userId}` : "";

  const hasAssignee = !!getAssignee(dataTask);

  return (
    <Modal
      title="Config design"
      open={open}
      onClose={toggleShowModal}
      sectioned
      large
      // size="Full"
    >
      {loading ? (
        <SkeletonPagePolaris />
      ) : (
        <div>
          {hasAssignee ? (
            <div style={{ textAlign: "center", margin: 24 }}>
              <h3>This task has been assigned to someone else.</h3>
            </div>
          ) : getPODApp ? (
            <Container id="config-design_wrap">
              <iframe
                id="POD-iframe"
                title="Personalized"
                style={{
                  border: "none",
                  width: "100%",
                  height: "calc(100vh - 129px)",
                }}
                src={`${PODApp}/config-design?token=${getPODApp?.token}&taskId=${task?.id}&${pKey}`}
              />
            </Container>
          ) : (
            <div style={{ textAlign: "center" }}>
              <h3>
                Please config your personalized orders with PersonalBridge app
                to use this feature.
              </h3>
              <SkeletonPagePolaris />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

function getAssignee(res) {
  return get(res, "taskById.assignee");
}
