import { gql } from "apollo-boost";

export const CREATE_TIKTOK_PROMOTION = gql`
  mutation createTiktokPromotion($input: CreateTiktokPromotionInput!) {
    createTiktokPromotion(input: $input)
  }
`;

export const UPDATE_TIKTOK_PROMOTION = gql`
  mutation updateTiktokPromotion($input: UpdateTiktokPromotionInput!) {
    createTiktokPromotion(input: $input)
  }
`;

export const DELETE_TIKTOK_PROMOTION = gql`
  mutation deleteTiktokPromotion($promotionID: ID!) {
    createTiktokPromotion(promotionID: $promotionID)
  }
`;

export const TIKTOK_FETCH_PRODUCTS_BY_STORE = gql`
  mutation tiktokFetchProductsByStore($storeID: String!) {
    tiktokFetchProductsByStore(storeID: $storeID)
  }
`;

export const FETCH_TIKTOK_PROMOTION_PRODUCT = gql`
  mutation fetchTiktokPromotionProduct($promotionID: ID!) {
    fetchTiktokPromotionProduct(promotionID: $promotionID)
  }
`;

export const TIKTOK_DEACTIVATE_PROMOTIONS = gql`
  mutation deactiveTiktokPromotions($promotionIDs: [ID!]!) {
    deactiveTiktokPromotions(promotionIDs: $promotionIDs)
  }
`;

export const TIKTOK_UPDATE_TIKTOK_PROMOTION_RENEW = gql`
  mutation updateTiktokPromotionRenew($promotionID: ID!, $isRenew: Boolean!) {
    updateTiktokPromotionRenew(promotionID: $promotionID, isRenew: $isRenew)
  }
`;

export const CREATE_TIKTOK_CUSTOM_BRAND = gql`
  mutation createTiktokCustomBrand($storeID: ID!, $name: String!) {
    createTiktokCustomBrand(storeID: $storeID, name: $name) {
      data {
        id
      }
    }
  }
`;

export const CREATE_TIKTOK_IMAGE_TEMPLATE = gql`
  mutation createTiktokImageTemplate($input: CreateTiktokImageTemplateInput!) {
    createTiktokImageTemplate(input: $input) {
      id
    }
  }
`;

export const UPDATE_TIKTOK_IMAGE_TEMPLATE = gql`
  mutation updateTiktokImageTemplate($input: EditTiktokImageTemplateInput!) {
    updateTiktokImageTemplate(input: $input) {
      id
    }
  }
`;

export const DELETE_TIKTOK_IMAGE_TEMPLATE = gql`
  mutation deleteTiktokImageTemplate($id: ID!) {
    deleteTiktokImageTemplate(id: $id)
  }
`;

export const FETCH_TIKTOK_STORE_STARTUS = gql`
  mutation fetchTiktokStoreStatus($teamID: String, $storeID: String) {
    fetchTiktokStoreStatus(teamID: $teamID, storeID: $storeID)
  }
`;

export const CREATE_TIKTOK_QUICK_PUSH = gql`
  mutation createTiktokQuickPush($input: TiktokQuickPushInput!) {
    createTiktokQuickPush(input: $input) {
      id
    }
  }
`;
