import { Card, Page, SkeletonDisplayText } from "@shopify/polaris";
import SuspenseComp from "../../shared/SuspenseComp";
import ProductCrawlItemsFilter from "./ProductCrawlItemsFilter";
import ProductCrawlItemsTable from "./ProductCrawlItemsTable";
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

export default function TiktokProductCrawlItems() {
	const [filters, setFilters] = useState({
		limit: 20,
		offset: 0,
		order: "DESC",
		orderBy: "created_at",
		originSource: null,
		search: null,
	});
	const isMounted = useRef(true);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const safeSetFilters = (newFilters) => {
		if (!_.isEqual(filters, newFilters)) {
			if (isMounted.current) {
				setFilters(newFilters);
			}
		}
	};

	return (
		<Page title="Product Crawl Items" fullWidth>
			<Card sectioned>
				<SuspenseComp fallback={<SkeletonDisplayText />}>
					<ProductCrawlItemsFilter
						filters={filters}
						onFilterChange={safeSetFilters}
					/>
				</SuspenseComp>
			</Card>
			<Card>
				<ProductCrawlItemsTable filters={filters} />
			</Card>
		</Page>
	);
}
